import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useStore, useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	Grid,
	Typography,
	Button,
	Chip,
	ListItemIcon,
	MenuItem,
	Menu,
	IconButton,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import BackArrow from '../../../components/page/BackArrow';
import * as ListingsAPI from '../../../scripts/listings';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as Utility from '../../../scripts/utility';
import CompanyJobsModal from '../../../components/modals/CompanyJobsModal';
import ContactJobsModal from '../../../components/modals/ContactJobsModal';
import BullhornIcon from '../../../components/icons/BullhornIcon';
import BiUser from '../../../components/icons/BiUser';
import JobLogoLinks from '../../../components/matching/JobLogoLinks';
import JobEditHeaderModal from './EditHeaderModal';
import JobCopyModal from '../../../components/modals/JobCopyModal';

const Block = ({ job, setJob }) => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const dispatch = useDispatch();
	const history = useHistory();

	const [moreMenu, setMoreMenu] = useState({ anchor: null, listing: {} });
	const [updatingBypassIntake, setUpdatingBypassIntake] = useState(false);
	const listingID = job?.id;

	const sendAssessment = (ev, callback) => {
		ListingsAPI.sendAssessment(job?.id, (response, err) => {
			if (!response) {
				let message = 'The email could not be sent. Please try again.';
				if (err === 'contact') message = 'A contact could not be found for this job. The assessment was not sent.';
				store.dispatch(SiteActions.showAlert(message, 'error'));
				callback?.(false);
				return;
			}
			if (callback) {
				callback?.(true);
			} else {
				store.dispatch(SiteActions.showAlert('The email was successfully sent.', 'success'));
			}
		});
	};

	return (
		<>
			<header
				style={{
					padding: '2rem 4rem',
					backgroundColor: 'var(--white)',
					boxShadow: '0px 3px 6px #00000015',
				}}
			>
				<Grid
					container
					spacing={1}
					style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 5 }}
				>
					<Grid item style={{ marginLeft: -30, display: 'flex', alignItems: 'center' }}>
						<BackArrow
							to="/jobs"
							onClick={e => {
								e.preventDefault();
								history.goBack();
							}}
						/>
						<Typography variant="h2" style={{ marginLeft: -5, fontWeight: 700 }}>
							{job?.position_title || `Job #${listingID}`} | {job?.vendor_job_id}
						</Typography>

						<JobLogoLinks job={job} />
						{job?.status_id ? (
							<Chip
								label={Utility.getFromObj(job, 'listing_status', 'label')}
								className={`color-${Utility.getListingStatusColor(Utility.getFromObj(job, 'listing_status', 'name'))}`}
								style={{ margin: 0, marginLeft: '1.5rem' }}
							/>
						) : null}
						<Button
							variant="text"
							color="primary"
							size="small"
							onClick={() => {
								dispatch(
									SiteActions.showModal(<JobEditHeaderModal job={job} setJob={setJob} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
							}}
						>
							EDIT
						</Button>
					</Grid>

					<Grid item>
						<NavLink to={`/job/${listingID}/details`}>
							<Button variant="contained" color="primary" size="small">
								Company Overview
							</Button>
						</NavLink>
						<IconButton style={{ marginLeft: 20 }} onClick={ev => setMoreMenu({ anchor: ev.target, job })}>
							<Icons.MoreVert color="primary" />
						</IconButton>
						{moreMenu ? (
							<Menu
								anchorEl={moreMenu?.anchor}
								open={Boolean(moreMenu?.anchor)}
								onClose={() => setMoreMenu({ ...moreMenu, anchor: null })}
								keepMounted
								className="link-menu"
							>
								<MenuItem style={{ minWidth: 250, padding: '0px 16px' }}>
									<ListItemIcon>
										<FormControlLabel
											style={{ marginLeft: -20, marginRight: -10 }}
											control={
												<Switch
													checked={job?.bypass_intake}
													disabled={job?.bypassed_by_match}
													onChange={() => {
														// setMoreMenu({ ...moreMenu, anchor: null });
														setUpdatingBypassIntake(true);
														ListingsAPI.updateListingBypassIntake(
															listingID,
															{
																bypass_intake: !job?.bypass_intake,
															},
															(response, err) => {
																if (err) {
																	console.log(err);
																} else {
																	setJob(response.data.data);
																}
																setUpdatingBypassIntake(false);
															},
														);
													}}
													name="checkedB"
													color="primary"
												/>
											}
											label={
												<span style={{ fontSize: 16 }}>
													{updatingBypassIntake
														? 'Updating...'
														: job?.bypass_intake
														? 'Intake Bypassed'
														: 'Intake Required'}
												</span>
											}
										/>
									</ListItemIcon>
								</MenuItem>
								<MenuItem
									disabled={!job?.contacts?.[0]?.user?.claimed_at || job?.bypass_intake || job?.assessment_completed_at}
								>
									<NavLink
										to="#0"
										onClick={() => {
											sendAssessment();
										}}
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,
											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.Send color="primary" />
										</ListItemIcon>
										Send Job Notification
									</NavLink>
								</MenuItem>
								<MenuItem>
									<NavLink
										to={`/job/${listingID}/matches`}
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,
											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.Search color="primary" />
										</ListItemIcon>
										View Suggested Matches
									</NavLink>
								</MenuItem>
								{/* <MenuItem>
									<NavLink
										to={`/job/${listingID}/match-testing`}
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,
											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.TrendingUp color="primary" />
										</ListItemIcon>
										Testing
									</NavLink>
								</MenuItem> */}
								<MenuItem>
									<NavLink
										to={`/contact/${Utility.getFromObj(job, 'contacts', 0, 'id')}/profile`}
										target="_blank"
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,
											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.Person color="primary" />
										</ListItemIcon>
										View Contact Profile
									</NavLink>
								</MenuItem>
								<MenuItem>
									<NavLink
										to={`/job/${Utility.getFromObj(job, 'id')}/profile`}
										target="_blank"
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,
											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.WorkOutline color="primary" />
										</ListItemIcon>
										View Job Profile
									</NavLink>
								</MenuItem>
								<MenuItem>
									{job?.vendor_job_id ? (
										<a
											href={`${process.env.REACT_APP_BULLHORN_JOB_ADR}${job?.vendor_job_id}`}
											target="_blank"
											rel="noreferrer"
											style={{
												color: 'var(--grey-400)',
												fontWeight: 300,
												fontSize: 16,
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<ListItemIcon>
												<BullhornIcon color="#F2591A" width={20} height={20} />
											</ListItemIcon>
											View Bullhorn Profile
										</a>
									) : (
										<Typography variant="body2">{Utility.nb}</Typography>
									)}
								</MenuItem>

								<MenuItem
									onClick={e => {
										e.preventDefault();
										dispatch(SiteActions.showModal(<JobCopyModal job={job} setJob={setJob} />));
									}}
								>
									<a
										href="#0"
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,
											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.FileCopy color="primary" />
										</ListItemIcon>
										Copy Job Data
									</a>
								</MenuItem>
							</Menu>
						) : null}
						{/* )} */}
					</Grid>
				</Grid>

				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : null}

				<Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
					<Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
						<Typography variant="body1">
							{!!job?.company && (
								<Button
									color="primary"
									size="small"
									style={{ marginLeft: 0, marginTop: 5 }}
									variant="text"
									onClick={() => {
										store.dispatch(
											SiteActions.showModal(
												<CompanyJobsModal sendAssessment={sendAssessment} company={{ name: job?.company, id: 1 }} />,
												{
													className: 'modal-medium modal-no-shadow',
												},
											),
										);
									}}
								>
									<Icons.Business color="primary" style={{ marginRight: 5 }} /> {job?.company || Utility.nb}
								</Button>
							)}
						</Typography>
						<Typography variant="body1">
							<Button
								color="primary"
								style={{ marginLeft: 0, marginTop: 5 }}
								variant="text"
								size="small"
								onClick={() => {
									store.dispatch(
										SiteActions.showModal(
											<ContactJobsModal job={job} sendAssessment={sendAssessment} contact={job?.contacts?.[0]} />,
											{
												className: 'modal-medium modal-no-shadow',
											},
										),
									);
								}}
							>
								<div style={{ marginRight: 5, display: 'flex' }}>
									{job?.contacts?.[0]?.user?.claimed_at ? (
										<>
											<BiUser color="rgb(0, 187, 0)" />
											{/* <BiUserCheck color="rgb(0, 187, 0)" /> */}
										</>
									) : (
										<>
											<BiUser color="#FA5152" />
											{/* <BiUserX color="#FA5152" /> */}
										</>
									)}
								</div>
								<Typography variant="body" style={{ marginRight: 10 }}>
									{`${Utility.getJobContactField(job?.contacts, 'first_name')} ${Utility.getJobContactField(
										job?.contacts,
										'last_name',
									)}` || Utility.nb}
								</Typography>
							</Button>
							{/* <Icons.Email color="primary" />{' '} */}
						</Typography>
						{/* <Typography variant="body2" style={{ marginBottom: 8 }}>
							<Icons.Phone color="primary" />{' '}
						</Typography> */}
					</Grid>
					<Grid
						item
						xs={6}
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
							justifyContent: 'flex-end',
						}}
					>
						<Typography variant="caption" style={{ fontStyle: 'italic' }}>
							Last Updated: {Utility.formatDateTime(job?.last_profile_update)} | Intake Completed:{' '}
							{Utility.formatDateTime(job?.assessment_completed_at)}
						</Typography>
					</Grid>
				</Grid>
			</header>
		</>
	);
};

export default Block;
