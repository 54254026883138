import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, Button, Typography } from '@material-ui/core';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import SkillsIcon from '../../../components/icons/SkillsIcon';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import CategoryDropdown from '../../../components/form/dropdowns/CategoryDropdown';
import SpecialtyDropdown from '../../../components/form/dropdowns/SpecialtyDropdown';
import ElasticSkillDropdown from '../../../components/form/dropdowns/ElasticSkillDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import SpecialtyCategoryDropdown from '../../../components/form/dropdowns/SpecialtyCategorySearchDropdown';
import * as Utility from '../../../scripts/utility';

const EditSkillsModal = ({ candidate, setCandidate }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const { data } = useSelector(state => state.site, shallowEqual);
	const [isSaving, setIsSaving] = useState(false);
	const [updatedCandidate, setUpdatedCandidate] = useState(candidate);
	// const [validSkills, setValidSkills] = useState([]);

	const updateCandidate = ev => {
		const newValue = { [ev.target.name]: ev.target.value };
		setUpdatedCandidate(x => ({ ...x, ...newValue }));
	};

	const updateSkills = (levelId, skillIds, skillCache) => {
		console.log({ skillIds });

		const removedSkillIds = updatedCandidate.skills
			?.filter(s => s.skill_experience_id === levelId)
			?.filter(s => !skillIds.includes(s.skill_id))
			?.map(s => s.skill_id);

		const newSkills = skillIds
			?.filter(skillId => !updatedCandidate.skills.find(s => s.skill_id === skillId))
			?.map(skillId => ({
				skill_id: skillId,
				skill: skillCache[skillId] || { id: skillId },
				skill_experience_id: levelId,
				experience_years: 0,
			}));

		const updatedSkills = updatedCandidate.skills
			?.filter(s => !removedSkillIds.includes(s.skill_id)) // remove skills
			?.map(s => (skillIds.includes(s.skill_id) ? { ...s, skill_experience_id: levelId } : s)) // update xp id
			?.concat(newSkills);

		setUpdatedCandidate(c => ({ ...c, skills: updatedSkills }));
	};

	const saveCandidate = () => {
		setIsSaving(true);

		const forgedCandidate = CandidatesAPI.getForgedCandidate(updatedCandidate);
		const forgedCurrentCandidate = CandidatesAPI.getForgedCandidate(candidate);

		const differencesToSubmit = Utility.getEntityDifferences(forgedCurrentCandidate, forgedCandidate);
		// differencesToSubmit.skills = updatedCandidate.skills;

		CandidatesAPI.updateCandidate(candidate.id, differencesToSubmit, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			const newCandidate = response.data.data;
			CandidatesAPI.addCandidateSkills(candidate.id, updatedCandidate.skills, skillResponse => {
				if (!skillResponse) {
					setIsSaving(false);
					store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
					return;
				}
				newCandidate.skills = skillResponse.data.data;
				setCandidate(newCandidate);
				dispatch(SiteActions.hideModal());
			});
		});
	};

	// useEffect(() => {
	// 	if (!validSkills.length) {
	// 		CandidatesAPI.getValidSkills(candidate.id, response => {
	// 			if (response) setValidSkills(response.data.data);
	// 		});
	// 	}
	// }, [candidate.id]);

	return (
		<Panel>
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<SkillsIcon size={26} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						Skills
					</Typography>
				</div>
			</PanelHeader>
			<PanelContent>
				<Grid item xs={12}>
					<SpecialtyCategoryDropdown onChange={updateCandidate} model={updatedCandidate} />
				</Grid>
				{/* <Grid container spacing={2}>
					<Grid item xs={5}>
						<LabeledField label="Category" required>
							<CategoryDropdown
								variant="outlined"
								name="professional_category_id"
								value={updatedCandidate.professional_category_id}
								onChange={updateCandidate}
								error={!updatedCandidate.professional_category_id}
								fullWidth
							/>
						</LabeledField>
					</Grid>
					<Grid item xs={2} />
					<Grid item xs={5}>
						<LabeledField label="Specialty">
							<SpecialtyDropdown
								variant="outlined"
								name="specialty_id"
								value={updatedCandidate.specialty_id}
								placeholder="Choose from our list"
								category={updatedCandidate.professional_category_id}
								onChange={updateCandidate}
								disabled={
									!updatedCandidate.professional_category_id ||
									updatedCandidate.leadership_allocation_type_id === 3 ||
									updatedCandidate.leadership_allocation_type_id === 4
								}
								fullWidth
							/>
						</LabeledField>
					</Grid>
				</Grid> */}
				{[...data.skillExperienceLevels]
					.reverse()
					.filter(x => x.id !== 1) /* Filter out the "Uncategorized" experience level. */
					.map(level => (
						<LabeledField
							key={level.name}
							label={
								{
									Expert: 'Best Skills',
									Advanced: 'Advanced Skills',
									Intermediate: 'Intermediate Skills',
									Novice: 'Basic Skills',
								}[level.label] || level.label
							}
							// required
						>
							<ElasticSkillDropdown
								tags
								variant="outlined"
								color="primary"
								name={`skills_${level.name}`}
								value={updatedCandidate?.skills
									?.filter(s => s.skill_experience_id === level.id && !s.skill.needs_review)
									?.map(s => s.skill_id)}
								// override={validSkills}
								additionalSkills={updatedCandidate?.skills
									?.map(s => ({
										id: s.skill_id,
										label: s.skill?.label || 'Unknown',
									}))
									?.sort((a, b) => a?.label?.localeCompare(b?.label))}
								onChange={(e, skillCache) => {
									updateSkills(level.id, e.target.value, skillCache);
								}}
								// error={!updatedCandidate?.skills?.filter(s => s.skill_experience_id === level.id)}
								fullWidth
							/>
						</LabeledField>
					))}
				<div className="text-right">
					<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveCandidate}>
						Save
					</LoadingButton>
				</div>
			</PanelContent>
		</Panel>
	);
};

EditSkillsModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditSkillsModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditSkillsModal;
