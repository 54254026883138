import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import BullhornIcon from '../icons/BullhornIcon';
import LinkedinIcon from '../icons/LinkedinIcon';
import { ActionCreators as SiteActions } from '../../store/Site';
import SharePermissionsModal from '../modals/SharePermissionsModal';
import CandidateContactModal from '../modals/CandidateContactModal';
import BiUser from '../icons/BiUser';

const CandidateLogoLinks = ({ candidate, onRefetchMatches, isSharable }) => {
	const dispatch = useDispatch();

	const bid = candidate?.vendor_candidate_id;
	const bullhornLink = `https://cls30.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${bid}`;

	const httpLinkedIn = candidate?.linkedin_url?.startsWith('linkedin')
		? `https://${candidate?.linkedin_url}`
		: candidate?.linkedin_url || '#';

	if (!candidate) {
		return null;
	}

	return (
		<div style={{ display: 'inline-block', marginLeft: 10 }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{candidate?.linkedin_url ? (
					<a
						style={{
							opacity: candidate?.linkedin_url ? 1 : 0.2,
						}}
						href={httpLinkedIn}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Button
							variant="text"
							color="#000"
							size="small"
							style={{ margin: 0, padding: 4, minWidth: 0, borderRadius: 4 }}
						>
							<LinkedinIcon width={20} height={20} />
						</Button>
					</a>
				) : (
					<div style={{ opacity: 0.2 }}>
						<Button
							variant="text"
							color="#000"
							size="small"
							style={{ margin: 0, padding: 4, minWidth: 0, borderRadius: 4 }}
						>
							<LinkedinIcon width={20} height={20} />
						</Button>
					</div>
				)}

				<div style={{ width: 8 }} />
				{candidate?.vendor_candidate_id ? (
					<a href={candidate?.vendor_candidate_id ? bullhornLink : '#'} target="_blank" rel="noopener noreferrer">
						<Button
							variant="text"
							color="#000"
							size="small"
							style={{ margin: 0, padding: 4, minWidth: 0, borderRadius: 4 }}
						>
							<BullhornIcon color="#F2591A" width={20} height={20} />
						</Button>
					</a>
				) : (
					<div style={{ opacity: 0.2 }}>
						<Button
							variant="text"
							color="#000"
							size="small"
							style={{ margin: 0, padding: 4, minWidth: 0, borderRadius: 4 }}
						>
							<BullhornIcon color="#F2591A" width={20} height={20} />
						</Button>
					</div>
				)}
				<div style={{ width: 8 }} />

				<div style={{ marginRight: 5 }}>
					<Button
						variant="text"
						color="#000"
						size="small"
						style={{ margin: 0, padding: 4, minWidth: 0, borderRadius: 4 }}
						onClick={() => {
							dispatch(
								SiteActions.showModal(<CandidateContactModal candidate={candidate} />, {
									className: 'modal-medium modal-no-shadow',
								}),
							);
						}}
					>
						{candidate?.user?.claimed_at ? (
							<BiUser color="rgb(0, 187, 0)" size={24} />
						) : (
							<BiUser color="#FA5152" size={24} />
						)}
					</Button>
				</div>

				<Button
					variant="text"
					color="#000"
					size="small"
					style={{ margin: 0, padding: 4, minWidth: 0, borderRadius: 4 }}
					onClick={() => {
						dispatch(
							SiteActions.showModal(
								<SharePermissionsModal
									candidate={candidate}
									onRefetchMatches={onRefetchMatches}
									isSharable={isSharable}
								/>,
								{
									className: 'modal-small modal-no-shadow',
								},
							),
						);
					}}
				>
					<Icons.Share style={{ color: isSharable ? '#00bb00' : '#ff2222' }} />
				</Button>
			</div>
		</div>
	);
};

export default CandidateLogoLinks;
