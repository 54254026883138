import React, { useEffect, useState } from 'react';
import { Provider, shallowEqual, useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import '@elastic/react-search-ui-views/lib/styles/styles.css';

import ElasticsearchAPIConnector from '@elastic/search-ui-elasticsearch-connector';
import { Layout } from '@elastic/react-search-ui-views';
import {
	ErrorBoundary,
	Facet,
	SearchProvider,
	SearchBox,
	Results,
	PagingInfo,
	ResultsPerPage,
	Paging,
	Sorting,
	WithSearch,
	withSearch,
} from '@elastic/react-search-ui';
import elasticsearch from 'elasticsearch';

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import 'ag-grid-community/dist/styles/ag-theme-base.css'; // Optional theme CSS

import Dropdown from './Dropdown';
import TagDropdown from './TagDropdown';
import SkillDropdown from './SkillDropdown';
import { isLocalAPI, isStagingAPI } from '../../../scripts/api';
import { useContextStore } from '../../../store/ContextStore';

const IS_STAGING_OR_LOCAL = isLocalAPI || isStagingAPI;

const HOST_URL = IS_STAGING_OR_LOCAL
	? 'https://nauwork-portal-elastic-dev.es.westus2.azure.elastic-cloud.com'
	: 'https://nauwork-portal-elastic-prod.es.westus2.azure.elastic-cloud.com/';
const CLOUD_ID = IS_STAGING_OR_LOCAL
	? // eslint-disable-next-line max-len
	  'nauwork-portal-elastic-dev:d2VzdHVzMi5henVyZS5lbGFzdGljLWNsb3VkLmNvbTo0NDMkZGZjMzA4MWZhMTIzNDJmNDkwZTgyMTJjOGMwNzRmYzEkYzk0OGVhNzEzMzEzNDZiODgxZGZjZDhiMjJiZDNiMjk='
	: // eslint-disable-next-line max-len
	  'nauwork-portal-elastic-prod:d2VzdHVzMi5henVyZS5lbGFzdGljLWNsb3VkLmNvbTo0NDMkZWFiODY3MDM5MWFkNGY2Yjk4MWYxMDNhZWY1MzUxOWMkODEzY2Q5Yzg2OWNkNDkzNjkwNGRlOWNmZTNhNmU5NWU=';

const useElasticSkillsLoad = ({ search, skillIds, onLoad }) => {
	const { currentAccessToken } = useContextStore();
	// eslint-disable-next-line max-len
	// 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNmM0ZjQzNTEwYTAzNjcyZjBiMzAxMWNlNjQzOGY2YzRmYjQyMjllYTU1MmExOWFlZDE2ODcwZTUzODZjNmU3YmYzY2JhMWJmYzc5YzVhMzQiLCJpYXQiOjE3MTE4NTU3ODQuNDg3MTYxLCJuYmYiOjE3MTE4NTU3ODQuNDg3MTY1LCJleHAiOjE3MTE4NTkzODQuMzg5OTMzLCJzdWIiOiIzMTQxIiwic2NvcGVzIjpbImdsb2JhbC1zY29wZSJdLCJpc3MiOiJodHRwczovL3N0YWdpbmctYXBpLm5hdXdvcmsubmV0IiwiZ3JvdXBzIjoicG9ydGFsX2luZGV4ZXNfYWxsX3JvIn0.ki0Gn6y1wXclvQTrz306s2Fxf3g8Uo9O7MPQZf25_gD-B7yuWqVBglBPEY4NdOX2WStIfivmaIxiQVIa4gbsfZsC4KefjDJ3a6Jj7R8K7Oy3oEqv20n-pkoyvGdLVhsCMisY18crTkf1FcAesesPzE04JM9JwRZYKHyfMgB7ci48EdSPHwb1yH9FlEENMGaUnP7_-nawXOFys7lgwv9seyiCoSzD5NobjPbIzzTXyxya8GmO5P5gQZX_8zfWMuu1nQFCzVHwhSWpA_9BvSW-_7d2KWinBKYBKSNCQ2H1mecvfjWE7d6zgFmSiMur854R4tvlO94TuDJ6itn0aPGoiRNpK7-TTHer55j-zDxiLN0iXGFs40_NPtCg3RAQOBbdtD9CmxQLQzPjIB6L_SwQL38E-hresquhxDRCn39M2b7MkCAcqmVg50iDq2wY6yGuk4TohTZpK7WLbJIVxaQuctIHM5lW3_H1fhz-SevujKESNvcvTSGe1himDkS6jBwV4F5_sAr1tNSz2xYDUQJxbxxtlS3VfkB6kaIfId797p0fZ6Bv95ISxjacnPRQctDjreffU3wwc6kSEMD1xWF2e-0LSqyKYeHUVxHi35MyjPx2Nfyf9XZ5UFBlWhPgpa4uNnxyk6QDfBXGxUu75qv5o5ePeo3poC-Wiir1Hov2ZVU';

	const [results, setResults] = useState(null);

	useEffect(() => {
		if (currentAccessToken && skillIds) {
			// cloud connector example
			const config = {
				cloud: {
					id: CLOUD_ID,
				},
				host: HOST_URL, // 'https://nauwork-portal-elastic-dev.es.westus2.azure.elastic-cloud.com',
			};

			const client = new elasticsearch.Client(config);

			// console.log({ client });

			client
				.search({
					body: {
						size: 100,
						query: {
							terms: {
								id: skillIds,
							},
						},
					},
					index: 'skills_index',
					headers: { Authorization: `Bearer ${currentAccessToken}` },
				})
				.then(
					body => {
						if (body?.hits?.hits) {
							try {
								onLoad(
									body.hits.hits
										// eslint-disable-next-line no-underscore-dangle
										.map(x => x._source)
										.reduce(
											(acc, x) => ({
												...acc,
												[parseFloat(x.id)]: {
													label: x.label,
													id: parseFloat(x.id),
												},
											}),
											{},
										),
								);
							} catch (e) {
								console.error(e);
							}
						}
					},
					function(error) {
						console.trace(error.message);
					},
				)
				.catch(e => {
					console.log(e);
				});
		}
		// onLoad(results);
	}, [currentAccessToken, skillIds]);

	if (!currentAccessToken) {
		return null;
	}

	return null;
};

const ElasticSkillDropdown = ({
	value,
	onChange,
	override,
	additionalSkills,
	filter,
	getCategoryLabel,
	tags,
	...props
}) => {
	const { currentAccessToken } = useContextStore();
	const [cachedSelectedItems, setCachedSelectedItems] = useState([]);

	const [initialSkillIds] = useState(value ? (value?.length !== undefined ? value : [value]) : []);
	useElasticSkillsLoad({
		skillIds: initialSkillIds,
		onLoad: skills => {
			setCachedSelectedItems(skills);
		},
	});

	if (!currentAccessToken) {
		return null;
	}

	// cloud connector example
	const connector = new ElasticsearchAPIConnector({
		cloud: {
			id:
				// eslint-disable-next-line max-len
				CLOUD_ID,
		},
		host: HOST_URL, // 'https://nauwork-portal-elastic-dev.es.westus2.azure.elastic-cloud.com',
		index: 'skills_index',
		connectionOptions: {
			headers: { Authorization: `Bearer ${currentAccessToken}` },
		},
	});

	const config = {
		trackUrlState: false,
		searchQuery: {
			search_fields: {
				label: {
					weight: 3,
				},
			},
			result_fields: {
				label: {
					snippet: {},
				},
			},
		},
		apiConnector: connector,
		alwaysSearchOnInitialLoad: false,
		autocompleteQuery: {
			suggestions: {
				types: {
					documents: {
						fields: ['label.suggest'],
					},
				},
			},
		},
	};

	const handleOnChange = (e, skillsList) => {
		// If there's a new selected item, cache it.
		const newId = e.target.value?.find?.(x => !value.includes(x));
		const newLabel = skillsList?.find(x => (x?.id?.raw || x?.id)?.toString() === newId?.toString())?.label;
		let skillCache = null;
		if (newId && newLabel) {
			setCachedSelectedItems(x => {
				skillCache = {
					...x,
					[parseFloat(newId)]: {
						id: parseFloat(newId),
						label: newLabel,
					},
				};

				return skillCache;
			});
		}

		onChange(e, skillCache);
	};

	if (override) {
		return (
			<SkillDropdown
				value={value}
				onChange={e => handleOnChange(e, override)}
				override={override}
				tags={tags}
				{...props}
			/>
		);
	}

	const DropdownComponent = tags ? TagDropdown : Dropdown;
	console.log({ config });
	// return null;

	return (
		<SearchProvider
			config={config}
			// routingOptions={{
			// 	writeUrl: () => undefined,
			// 	readUrl: () => undefined,
			// 	urlToState: () => ({}),
			// }}
			trackUrlState={false}
		>
			<div>
				<WithSearch
					mapContextToProps={context => {
						return {
							wasSearched: context.wasSearched,
							results: context.results,
							searchTerm: context.searchTerm,
							setSearchTerm: context.setSearchTerm,
						};
					}}
				>
					{({ wasSearched, results: _results, searchTerm, setSearchTerm }) => {
						// Don't display the random default Elastic results if the user hasn't typed a term yet.
						const results = searchTerm ? _results : null;

						let skillData = [...(results?.map(x => ({ id: x.id.raw, label: x.label.raw })) || [])]?.map(x => ({
							...x,
							id: parseFloat(x.id),
						}));

						const cachedSkills = Object.keys(cachedSelectedItems).map(x => ({
							id: parseFloat(x),
							label: cachedSelectedItems[x]?.label,
						}));
						if (cachedSkills?.length) {
							cachedSkills.forEach(skill => {
								if (!skillData.find(x => x.id === skill.id)) {
									skillData.push(skill);
								}
							});
						}

						if (additionalSkills?.length) {
							additionalSkills?.forEach(skill => {
								if (!skillData.find(x => x.id === skill.id)) {
									skillData.push(skill);
								}
							});
						}
						if (getCategoryLabel) {
							skillData = [...skillData]
								.map(x => ({ ...x, category_label: getCategoryLabel(x?.id) }))
								?.sort((a, b) => b?.category_label?.localeCompare(a?.category_label));
						}

						// const stringValue = value?.map ? value?.map(x => x.toString()) : value ? value?.toString() : value;
						return (
							<div>
								<DropdownComponent
									value={value}
									inputValue={searchTerm || ''}
									disabled={override && !override?.length}
									groupBy={s => s.category_label}
									onInputChange={x => {
										if (x) {
											setSearchTerm(x?.target?.value);
										}
									}}
									data={skillData}
									filter={x => {
										if (filter) {
											return filter(x?.id);
										}
										return !!x;
									}}
									onChange={e => handleOnChange(e, skillData)}
									placeholder="Search for skills..."
									{...props}
								/>
							</div>
						);
					}}
				</WithSearch>
			</div>
		</SearchProvider>
	);
};

export default ElasticSkillDropdown;
