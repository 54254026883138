import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import Page from '../../components/page/Page';
import StickyHeader from '../../components/StickyHeader';
import { Panel } from '../../components/Panel';
import * as ListingsAPI from '../../scripts/listings';
import CandidateCardList from '../../components/matching/CandidateCardList';
import JobLogoLinks from '../../components/matching/JobLogoLinks';
import ViewDetailsBlock from './JobBlocks/ViewDetailsBlock';
import ViewExperienceBlock from './JobBlocks/ViewExperienceBlock';
import ViewLocationBlock from './JobBlocks/ViewLocationBlock';
import ViewDescriptionBlock from './JobBlocks/ViewDescriptionBlock';
import ViewAdminBlock from './JobBlocks/ViewAdminBlock';
import ViewSkillsBlock from './JobBlocks/ViewSkillsBlock';
import ViewPreparationBlock from './JobBlocks/ViewPreparationBlock';
import ViewScreeningQuestionsBlock from './JobBlocks/ViewScreeningQuestionsPanel';
import ViewHeaderBlock from './JobBlocks/ViewHeaderBlock';
import ViewMoreDetailsBlock from './JobBlocks/ViewMoreDetailsBlock';
import ViewRecruiterResourcesBlock from './JobBlocks/ViewRecruiterResourcesBlock';

import { useContextStore } from '../../store/ContextStore';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import * as Utility from '../../scripts/utility';

const JobView = () => {
	const params = useParams();
	const listingID = params.id;
	const [job, setJob] = useState({});
	const { data, sidebarWidth } = useSelector(state => state.site, shallowEqual);

	const [isMoreDetailsMinimized, setIsMoreDetailsMinimized] = Utility.useStickyState(
		false,
		'job-view-more-details-minimized',
	);

	const [isRecruiterResourcesMinimized, setIsRecruiterResourcesMinimized] = Utility.useStickyState(
		false,
		'job-view-recruiter-resources-minimized',
	);
	const [isScreeningQuestionsMinimized, setIsScreeningQuestionsMinimized] = Utility.useStickyState(
		false,
		'job-view-screening-questions-minimized',
	);
	const [isLocationMinimized, setIsLocationMinimized] = Utility.useStickyState(false, 'job-view-location-minimized');
	const [isDetailsMinimized, setIsDetailsMinimized] = Utility.useStickyState(false, 'job-view-details-minimized');
	const [isExperienceMinimized, setIsExperienceMinimized] = Utility.useStickyState(
		false,
		'job-view-experience-minimized',
	);
	const [isDescriptionMinimized, setIsDescriptionMinimized] = Utility.useStickyState(
		false,
		'job-view-description-minimized',
	);
	const [isPreparationMinimized, setIsPreparationMinimized] = Utility.useStickyState(
		false,
		'job-view-preparation-minimized',
	);
	const [isSkillsMinimized, setIsSkillsMinimized] = Utility.useStickyState(false, 'job-view-skills-minimized');

	useDocumentTitle(
		job && job.position_title
			? `${job.position_title || 'N/A'}
		- ${job.company || 'N/A'} |  NauMatch`
			: 'Job | NauMatch',
	);

	const { jobMatches: matches, refetchMatches, loadingJobMatches: loadingMatches } = useContextStore();

	const refreshJob = () => {
		ListingsAPI.getListing(listingID, response => {
			if (!response) return;
			setJob(response.data.data);
		});

		refetchMatches({ jobId: listingID });
	};

	// Get page data on load
	useEffect(() => {
		refreshJob();
	}, []);

	if (Object.keys(job).length === 0) return <Page />;

	return (
		<Page header={<ViewHeaderBlock job={job} setJob={setJob} />}>
			<div>
				<StickyHeader
					style={{
						width: `calc(100% - ${sidebarWidth}px)`,
						justifyContent: 'flex-start',
					}}
				>
					<Typography
						variant="body2"
						style={{
							color: '#fff',
							marginLeft: -5,
							fontWeight: 700,
							fontSize: 14,
						}}
					>
						{job.position_title || `Job #${listingID}`} | {job.company}
					</Typography>
					<div style={{ marginLeft: 0 }}>
						<JobLogoLinks job={job} />
					</div>
				</StickyHeader>
			</div>
			<Grid container spacing={4}>
				<Grid item md={12} lg={6}>
					<Panel topBarColor="blue">
						<ViewDetailsBlock
							job={job}
							setJob={setJob}
							data={data}
							minimized={isDetailsMinimized}
							setMinimized={setIsDetailsMinimized}
						/>
					</Panel>
					<Panel topBarColor="blue">
						<ViewExperienceBlock
							job={job}
							setJob={setJob}
							data={data}
							minimized={isExperienceMinimized}
							setMinimized={setIsExperienceMinimized}
						/>
					</Panel>
					<Panel topBarColor="blue">
						<ViewLocationBlock
							job={job}
							setJob={setJob}
							minimized={isLocationMinimized}
							setMinimized={setIsLocationMinimized}
							data={data}
						/>
					</Panel>
					<Panel topBarColor="blue">
						<ViewRecruiterResourcesBlock
							minimized={isRecruiterResourcesMinimized}
							setMinimized={setIsRecruiterResourcesMinimized}
							job={job}
							setJob={setJob}
							data={data}
						/>
					</Panel>
					<Panel topBarColor="blue">
						<ViewScreeningQuestionsBlock
							minimized={isScreeningQuestionsMinimized}
							setMinimized={setIsScreeningQuestionsMinimized}
							job={job}
							setJob={setJob}
							data={data}
						/>
					</Panel>
					<Panel topBarColor="blue">
						<ViewMoreDetailsBlock
							job={job}
							minimized={isMoreDetailsMinimized}
							setMinimized={setIsMoreDetailsMinimized}
							setJob={setJob}
							data={data}
						/>
					</Panel>
				</Grid>
				<Grid item md={12} lg={6}>
					<CandidateCardList
						roleOverride="account-executive"
						iconColor="var(--blue-100)"
						jobId={listingID}
						matches={matches}
						loading={loadingMatches}
						title="Saved Matches"
						small
					/>
					<Panel topBarColor="blue">
						<ViewSkillsBlock
							minimized={isSkillsMinimized}
							setMinimized={setIsSkillsMinimized}
							job={job}
							setJob={setJob}
							data={data}
							displaySkillsWithoutExperience
						/>
					</Panel>
					<Panel topBarColor="blue">
						<ViewDescriptionBlock
							displayDocumentManagement
							job={job}
							setJob={setJob}
							refreshJob={refreshJob}
							data={data}
							minimized={isDescriptionMinimized}
							setMinimized={setIsDescriptionMinimized}
						/>
					</Panel>
					<Panel topBarColor="blue">
						<ViewPreparationBlock
							job={job}
							setJob={setJob}
							data={data}
							minimized={isPreparationMinimized}
							setMinimized={setIsPreparationMinimized}
						/>
					</Panel>
				</Grid>
			</Grid>
		</Page>
	);
};

export default JobView;
