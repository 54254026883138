import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Card, Button, Menu, MenuItem, ListItemIcon, Tooltip, Grid } from '@material-ui/core';
import { useParams } from 'react-router';
import * as Icons from '@material-ui/icons';
import { Alert, Pagination } from '@material-ui/lab';
import elasticsearch from 'elasticsearch';

import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import CandidateDeleteModal from '../../components/modals/CandidateDeleteModal';
import LabeledField from '../../components/form/LabeledField';
import CandidateFilters from '../../components/filters/CandidateFilters';
import CandidateCardList from '../../components/matching/CandidateCardList';
import { SkillChip } from '../candidates/CandidateBlocks/ViewSkillsBlock';
import { isLocalAPI, isStagingAPI } from '../../scripts/api';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as Utility from '../../scripts/utility';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import { useContextStore } from '../../store/ContextStore';
import createElasticQuery from '../../lib/createElasticQuery';

const IS_STAGING_OR_LOCAL = isLocalAPI || isStagingAPI;

const JobMatches = () => {
	const store = useStore();
	const { data, alert, user } = useSelector(state => state.site, shallowEqual);

	const { role, getCurrentAccessToken } = useContextStore();

	useDocumentTitle('Recommended Candidates | NauMatch');

	const [filter, setFilter] = Utility.useStickyState({ isOpen: true, filters: {} }, 'admin-job-matches-filter');
	const table = useRef();

	const [activeSaveFilterId, setActiveSaveFilterId] = Utility.useStickyState(
		null,
		'admin-recommended-candidates-active-save-filter-id',
	);
	const [savedFilters, setSavedFilters] = Utility.useStickyState([], 'admin-recommended-candidates-saved-filtersets');

	const {
		jobMatches: matches,
		setJobMatches: setMatches,
		refetchMatches,
		graphMatches,
		setGraphMatches,
	} = useContextStore();

	const [activePageNum, setActivePageNum] = useState(1);
	const [numItems, setNumItems] = useState(0);
	const [paginationKey, setPaginationKey] = useState(0);
	const pageSize = 25;
	const numPages = numItems ? Math.ceil(numItems / pageSize) : 1;

	// const [graphMatches, setGraphMatches] = useState([]);

	const numFilters = Object.values(filter.filters || {}).filter(x => x).length;

	const openFilter = () => {
		setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		setFilter({ ...filter, isOpen: false });
	};

	const handleClearFilters = () => {
		setFilter({ ...filter, filters: {} });
	};

	const updateFilters = useCallback(
		(ev, name) => {
			setActivePageNum(1);
			if (typeof ev === 'object' && typeof name === 'string') {
				setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
			} else if (ev.target.type === 'checkbox') {
				setFilter({
					...filter,
					filters: {
						...filter.filters,
						[ev.target.name]: ev.target.checked ? ev.target.value : undefined,
					},
				});
			} else if (ev.target.value !== null && ev.target.value !== undefined && ev.target.value !== '') {
				setFilter({
					...filter,
					filters: { ...filter.filters, [ev.target.name]: ev.target.value },
				});
			} else {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
			}
		},
		[filter, setFilter],
	);

	const params = useParams();
	const listingID = params.id;
	const [job, setJob] = useState(null);
	const [loadingCandidates, setLoadingCandidates] = useState(true);
	const [menu, setMenu] = useState({ anchor: null, candidate: {} });

	const filteredGraphMatches = graphMatches?.filter((graphMatch, i) => {
		const filters = filter?.filters;
		const candidate = graphMatch?.candidate;
		const existingMatch = matches?.find(match => match?.candidate?.id === candidate?.id);
		// return true;

		if (!filters) {
			return true;
		}

		if (i === 0) {
			console.log({ candidate, filters });
		}

		let passFilter = true;
		if (filters.match_percentage_min !== undefined) {
			try {
				passFilter =
					passFilter && (graphMatch?.overall_match_score || 0) * 100 >= parseFloat(filters.match_percentage_min);
			} catch (e) {
				console.log('Error parsing match_percentage_min', e);
			}
		}
		if (filters.completeness_score_overall !== undefined) {
			try {
				passFilter =
					passFilter && (graphMatch?.completeness_score_overall || 0) * 100 >= filters?.completeness_score_overall;
			} catch (e) {
				console.log('Error parsing completeness_score_overall', e);
			}
		}
		if (filters.total_skill_score !== undefined) {
			try {
				passFilter = passFilter && (graphMatch?.total_skill_score || 0) * 100 >= filters?.total_skill_score;
			} catch (e) {
				console.log('Error parsing total_skill_score', e);
			}
		}
		if (filters.professional_experience_years_max !== undefined) {
			passFilter = passFilter && candidate?.professional_experience_years <= filters.professional_experience_years_max;
		}
		if (filters.professional_experience_years_min !== undefined) {
			passFilter = passFilter && candidate?.professional_experience_years >= filters.professional_experience_years_min;
		}
		if (filters.get_the_job_skills !== undefined && filters?.get_the_job_skills?.length) {
			passFilter =
				passFilter &&
				candidate?.skills?.some(skill => filters?.get_the_job_skills?.includes(skill?.id) && skill?.get_the_job);
		}
		if (filters.skills !== undefined && filters?.skills?.length) {
			passFilter = passFilter && candidate?.skills?.some(skill => filters?.skills?.includes(skill?.id));
		}
		if (filters.salary_expectation_type_min !== undefined) {
			passFilter = passFilter && candidate?.salary_expectation_type_id >= filters?.salary_expectation_type_min;
		}
		if (filters.salary_expectation_type_max !== undefined) {
			passFilter = passFilter && candidate?.salary_expectation_type_id <= filters?.salary_expectation_type_max;
		}
		if (filters.location_city !== undefined) {
			passFilter = passFilter && candidate?.city?.toLowerCase().includes(filters.location_city?.toLowerCase());
		}
		if (filters.location_state !== undefined) {
			try {
				passFilter =
					passFilter &&
					filters.location_state?.some(locationState =>
						candidate?.state?.toLowerCase().includes(locationState?.toLowerCase()),
					);
			} catch (e) {
				console.log({ candidate });
			}
		}
		if (filters.desired_locations !== undefined && filters?.desired_locations?.length) {
			passFilter = passFilter && candidate?.desired_locations?.some(x => filters.desired_locations?.includes(x.id));
		}
		if (filters.work_location_preferences !== undefined && filters?.work_location_preferences?.length) {
			passFilter =
				passFilter &&
				candidate?.work_location_preferences?.some(x => filters.work_location_preferences?.includes(x.id));
		}
		if (filters.work_visa_type_id !== undefined) {
			passFilter = passFilter && candidate?.work_visa_type_id === filters?.work_visa_type_id;
		}
		if (filters.resume !== undefined) {
			passFilter = passFilter && candidate?.resume?.includes(filters?.resume);
		}
		if (filters.current_title !== undefined) {
			passFilter = passFilter && candidate?.current_title?.includes(filters?.current_title);
		}
		if (filters.desired_position !== undefined) {
			passFilter = passFilter && candidate?.desired_position?.includes(filters?.desired_position);
		}
		if (filters.professional_category_id !== undefined && filters.professional_category_id?.length) {
			passFilter = passFilter && filters?.professional_category_id?.includes(candidate?.professional_category_id);
		}
		if (filters.specialty_id !== undefined && filters?.specialty_id?.length) {
			passFilter = passFilter && filters?.specialty_id?.includes(candidate?.specialty_id);
		}
		if (filters.recent_leadership_role_id !== undefined && filters?.recent_leadership_role_id?.length) {
			passFilter = passFilter && filters?.recent_leadership_role_id?.includes(candidate?.recent_leadership_role_id);
		}
		if (filters.total_leadership_years_max !== undefined) {
			passFilter = passFilter && candidate?.total_leadership_years <= filters?.total_leadership_years_max;
		}
		if (filters.total_leadership_years_min !== undefined) {
			passFilter = passFilter && candidate?.total_leadership_years >= filters?.total_leadership_years_min;
		}
		if (filters.industries !== undefined && filters?.industries?.length) {
			passFilter = passFilter && candidate?.industries?.some(x => filters?.industries?.includes(x?.id));
		}
		if (filters.status !== undefined && filters?.status?.length) {
			passFilter = passFilter && filters?.status?.includes(candidate?.status_type_id);
		}
		if (filters.owner !== undefined && filters?.owner?.length) {
			passFilter = passFilter && filters?.owner?.includes(candidate?.owner_id);
		}
		if (filters.match_saved !== undefined) {
			passFilter =
				passFilter && ((filters.match_saved === 1 && existingMatch) || (filters.match_saved === 2 && !existingMatch));
		}
		if (filters.match_shared !== undefined) {
			passFilter =
				passFilter &&
				((filters.match_shared === 1 && existingMatch?.is_published) ||
					(filters.match_shared === 2 && !existingMatch?.is_published));
		}
		if (filters.match_viewed !== undefined) {
			const myUserHasViewed = graphMatch?.views?.some(x => x?.viewed_by === user?.id);

			passFilter =
				passFilter &&
				((filters.match_viewed === 1 && myUserHasViewed) || (filters.match_viewed === 2 && !myUserHasViewed));
		}
		if (filters.fit_ranking?.length) {
			passFilter =
				passFilter &&
				filters.fit_ranking?.some(filterFitRankingId => {
					console.log({ filterFitRankingId });
					if (filterFitRankingId === 'none') {
						return !graphMatch?.fit_rankings?.find(y => y?.created_by === user?.id);
					}
					return graphMatch?.fit_rankings?.find(
						y => y?.created_by === user?.id && y?.match_fit_ranking?.id === filterFitRankingId,
					);
				});
		}
		/*
		'{"filter":{
			"match_percentage_min":"10",
			"professional_experience_years_max":"5",
			"professional_experience_years_min":"1",
			"get_the_job_skills":[10],
			"skills":[16],
			"salary_expectation_type_min":3,
			"salary_expectation_type_max":11,
			"location_city":"Greenville",
			"location_state":["South Carolina"],
			"desired_locations":[41],
			"work_location_preferences":[2,1],
			"work_visa_type_id":1,
			"resume":"test",
			"current_title":"test",
			"desired_position":"test",
			"professional_category_id":[19],
			"specialty_id":[88],
			"recent_leadership_role_id":[1],
			"total_leadership_years_max":"5",
			"total_leadership_years_min":"1",
			"industries":[8],
			"status":[9],
			"owner":[20]}}'
		*/
		return passFilter;
	});

	useEffect(() => {
		// Get the selected listing
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('Could not load the candidate. Refresh the page to try again.', 'error'));
				return;
			}
			const newJob = response.data.data;
			setJob(newJob);
		});
	}, []);

	useEffect(() => {
		// Get mock candidates list
		// CandidatesAPI.getCandidates({}, response => {
		// 	setCandidates(response?.data?.data);
		// });

		// ListingsAPI.getListingGraphMatches({ listingId: listingID }).then(async response => {
		// 	const matchData = response?.data?.data;
		// 	setGraphMatches(matchData);
		// 	setLoadingCandidates(false);
		// });

		const HOST_URL = IS_STAGING_OR_LOCAL
			? 'https://nauwork-portal-elastic-dev.es.westus2.azure.elastic-cloud.com'
			: 'https://nauwork-portal-elastic-prod.es.westus2.azure.elastic-cloud.com/';
		const CLOUD_ID = IS_STAGING_OR_LOCAL
			? // eslint-disable-next-line max-len
			  'nauwork-portal-elastic-dev:d2VzdHVzMi5henVyZS5lbGFzdGljLWNsb3VkLmNvbTo0NDMkZGZjMzA4MWZhMTIzNDJmNDkwZTgyMTJjOGMwNzRmYzEkYzk0OGVhNzEzMzEzNDZiODgxZGZjZDhiMjJiZDNiMjk='
			: // eslint-disable-next-line max-len
			  'nauwork-portal-elastic-prod:d2VzdHVzMi5henVyZS5lbGFzdGljLWNsb3VkLmNvbTo0NDMkZWFiODY3MDM5MWFkNGY2Yjk4MWYxMDNhZWY1MzUxOWMkODEzY2Q5Yzg2OWNkNDkzNjkwNGRlOWNmZTNhNmU5NWU=';

		const nestedQuery = {
			nested: {
				path: 'candidate',
				query: {
					bool: {
						must: [],
						should: [],
					},
				},
			},
		};
		const query = {
			bool: {
				must_not: [],
				must: [
					{
						term: {
							listing_id: listingID,
						},
					},
					nestedQuery,
				],
				should: [],
			},
		};

		const currentFilter = filter;

		createElasticQuery({
			object: 'candidate',
			currentFilter,
			query: nestedQuery.nested.query,
			objectPrefix: 'candidate.',
		});

		console.log({ currentFilter });

		if (currentFilter.filters.match_percentage_min) {
			try {
				query.bool.must.push({
					range: {
						overall_match_score: {
							gte: parseFloat(currentFilter.filters.match_percentage_min) / 100,
						},
					},
				});
			} catch (e) {
				console.log(e);
				console.log('Unable to parse match_percentage_min');
			}
		}

		if (currentFilter.filters.fit_ranking?.length) {
			// query.bool.must.push({
			// 	terms: {
			// 		'rankings.match_fit_ranking_status_id': currentFilter.filters.fit_ranking,
			// 	},
			// });

			query.bool.must.push({
				nested: {
					path: 'rankings',
					query: {
						bool: {
							must: [
								{
									terms: {
										'rankings.match_fit_ranking_status_id': currentFilter.filters.fit_ranking,
									},
								},
								{
									term: {
										'rankings.created_by': user?.id,
									},
								},
							],
						},
					},
				},
			});
		}

		if (currentFilter.filters.completeness_score_overall !== undefined) {
			try {
				query.bool.must.push({
					range: {
						completeness_score_overall: {
							gte: parseFloat(currentFilter.filters.completeness_score_overall) / 100,
						},
					},
				});
			} catch (e) {
				console.log(e);
				console.log('Unable to parse completeness_score_overall');
			}
		}

		if (currentFilter.filters.total_skill_score !== undefined) {
			try {
				query.bool.must.push({
					range: {
						total_skill_score: {
							gte: parseFloat(currentFilter.filters.total_skill_score) / 100,
						},
					},
				});
			} catch (e) {
				console.log(e);
				console.log('Unable to parse completeness_score_overall');
			}
		}

		if (currentFilter.filters.match_saved) {
			if (currentFilter.filters.match_saved === 1) {
				// query.bool.must.push({
				// 	exists: {
				// 		field: 'saved_matches',
				// 	},
				// });
				query.bool.must.push({
					nested: {
						path: 'saved_matches',
						query: {
							bool: {
								must: [
									{
										match: {
											'saved_matches.origin_model': role === 'recruiter' ? 'Candidate' : 'Listing',
										},
									},
								],
							},
						},
					},
				});
				// query.bool.must.push({
				// 	nested: {
				// 		path: 'saved_matches',
				// 		query: {
				// 			term: {
				// 		'saved_matches.origin_model': role === 'recruiter' ? 'App\\Models\\Listing' : 'App\\Models\\Candidate',
				// 			},
				// 		},
				// 	},
				// });
			} else {
				// query.bool.must_not.push({
				// 	exists: {
				// 		field: 'saved_matches',
				// 	},
				// });
				// query.bool.must_not.push({
				// 	nested: {
				// 		path: 'saved_matches',
				// 		query: {
				// 			exists: {
				// 				field: 'saved_matches',
				// 			},
				// 		},
				// 	},
				// });
				query.bool.must_not.push({
					nested: {
						path: 'saved_matches',
						query: {
							bool: {
								must: [
									{
										match: {
											'saved_matches.origin_model': role === 'recruiter' ? 'Candidate' : 'Listing',
										},
									},
								],
							},
						},
					},
				});
			}
		}

		if (currentFilter.filters.match_shared) {
			if (currentFilter.filters.match_shared === 1) {
				// query.bool.must.push({
				// 	term: {
				// 		'saved_matches.is_published': true,
				// 	},
				// });
				query.bool.must.push({
					nested: {
						path: 'saved_matches',
						query: {
							bool: {
								must: [
									{
										match: {
											'saved_matches.origin_model': role === 'recruiter' ? 'Candidate' : 'Listing',
										},
									},
									{
										term: {
											'saved_matches.is_published': true,
										},
									},
								],
							},
						},
					},
				});
			} else {
				// query.bool.must_not.push({
				// 	term: {
				// 		'saved_matches.is_published': true,
				// 	},
				// });

				query.bool.must_not.push({
					nested: {
						path: 'saved_matches',
						query: {
							bool: {
								must: [
									{
										match: {
											'saved_matches.origin_model': role === 'recruiter' ? 'Candidate' : 'Listing',
										},
									},
									{
										term: {
											'saved_matches.is_published': true,
										},
									},
								],
							},
						},
					},
				});
			}
		}

		if (currentFilter.filters.match_viewed) {
			if (currentFilter.filters.match_viewed === 1) {
				// query.bool.must.push({
				// 	exists: {
				// 		field: 'views',
				// 	},
				// });
				query.bool.must.push({
					nested: {
						path: 'views',
						query: {
							bool: {
								must: [
									{
										match: {
											'views.viewed_by.id': user?.id,
										},
									},
								],
							},
						},
					},
				});
			} else {
				query.bool.must_not.push({
					nested: {
						path: 'views',
						query: {
							bool: {
								must: [
									{
										match: {
											'views.viewed_by.id': user?.id,
										},
									},
								],
							},
						},
					},
				});
			}
		}

		getCurrentAccessToken().then(currentAccessToken => {
			console.log('got access token', currentAccessToken);
			if (currentAccessToken) {
				const config = {
					cloud: {
						id: CLOUD_ID,
					},
					host: HOST_URL, // 'https://nauwork-portal-elastic-dev.es.westus2.azure.elastic-cloud.com',
				};

				const client = new elasticsearch.Client(config);

				client
					.search({
						body: {
							size: 25,
							from: (activePageNum - 1) * pageSize,
							sort: [
								{
									overall_match_score: {
										order: 'desc',
									},
								},
							],
							query,
						},

						index: 'all_match_data_index',
						headers: { Authorization: `Bearer ${currentAccessToken}` },
					})
					.then(body => {
						// eslint-disable-next-line no-underscore-dangle
						// eslint-disable-next-line no-underscore-dangle
						console.log(
							// eslint-disable-next-line no-underscore-dangle
							body.hits.hits.map(x => x._source),
							body?.hits?.total?.value,
						);

						setNumItems(body?.hits?.total?.value || 0);

						setPaginationKey(x => x + 1);

						if (body?.hits?.hits) {
							// eslint-disable-next-line no-underscore-dangle
							setGraphMatches(body.hits.hits.map(x => x._source));
							setLoadingCandidates(false);
						}
					});
			}
		});

		// Get matches
		setMatches(null);
		refetchMatches({ jobId: listingID });
	}, [filter, activePageNum, role]);

	console.log({ matches, graphMatches });

	return (
		<Page filterPadding={filter.isOpen}>
			{/* <PageHeader.Left>
					<Typography variant="h1">Job Matches</Typography>
				</PageHeader.Left> */}

			{job && (
				<>
					<Card style={{ width: '100%' }}>
						<Typography variant="h3" style={{ marginBottom: 15 }}>
							<Icons.WorkOutline style={{ color: 'var(--blue-500)', marginRight: 10, marginTop: -4 }} />
							<NavLink to={`/job/${job.id}`} className="color-inherit">
								{job?.position_title} | {job?.company}
							</NavLink>
						</Typography>

						<Grid container spacing={2} style={{ marginLeft: 25 }}>
							<Grid item xs={6}>
								<LabeledField label="Pay" className="color-muted" close>
									<Typography variant="body2">
										<span>
											{Utility.formatMoney(job.salary_rate_min, { hideDecimal: true })} -{' '}
											{Utility.formatMoney(job.salary_rate_max, { hideDecimal: true })}
										</span>
									</Typography>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Hero Skills" className="color-muted" close>
									<Grid item xs={12} style={{ marginBottom: -12, paddingTop: 5 }}>
										{(job.skills || [])
											.filter(x => x.hero_skill)
											.map(skill => (
												<SkillChip
													baseColor="blue"
													key={skill?.id}
													skill={{
														...skill,
														// required_skill: true,
													}}
												/>
											))}
									</Grid>
									{/* <Typography variant="body2">
										{(job.skills || [])
											.filter(x => x.hero_skill)
											.map(skill => (
												<span>
													{skill?.skill?.label}
													{skill.experience_years ? ` (${skill.experience_years} yrs)` : null},{' '}
												</span>
											))}{' '}
									</Typography> */}
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Work & Location Preferences" className="color-muted" close>
									<Typography variant="body2">
										{Utility.getConsolidatedLocationPreferences(job?.work_location_preferences)?.join(', ')}
									</Typography>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Leadership" className="color-muted" close>
									<Typography variant="body2">
										{Utility.getItemLabel(data.leadershipExperienceRoles, job.recent_leadership_role_id) || Utility.nb}
										{job?.total_leadership_years ? ` (${job?.total_leadership_years} yrs)` : null}
									</Typography>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Status & Contact" className="color-muted" close>
									<Typography variant="body2">
										{Utility.getFromObj(job, 'listing_status', 'label')} •
										{`${Utility.getJobContactField(job.contacts, 'first_name')} ${Utility.getJobContactField(
											job.contacts,
											'last_name',
										)}` || Utility.nb}
									</Typography>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Visa Sponsorship" className="color-muted" close>
									<Typography variant="body2">{job.work_visa_types?.label || 'Unknown'}</Typography>
								</LabeledField>
							</Grid>
						</Grid>
					</Card>
				</>
			)}
			<PageHeader.Right>
				{/*
					<Button
						variant="contained"
						color="primary"
						onClick={() =>
							store.dispatch(
								SiteActions.showModal(
									<CandidateSyncModal category={menu.category} onSync={table.current.refreshData} />,
								),
							)
						}
						startIcon={<Icons.Sync />}
					>
						Sync Candidate
					</Button> */}
			</PageHeader.Right>
			<CandidateCardList
				loading={loadingCandidates}
				jobId={listingID}
				contextJob={job}
				// candidates={filteredCandidates}
				refreshMatches={() => {
					// refetchMatches()
					// Trigger an app refresh
					setFilter(x => ({ ...x }));
				}}
				// matches={matches}
				graphMatches={graphMatches}
				pageSize={25}
				disableLink
				enableSelectAll
				enableRefresh
				numFilters={numFilters}
				clearFilters={handleClearFilters}
				hidePagination
				filterComponent={
					<Tooltip className="tooltip" title={`${numFilters} Filter${numFilters > 1 ? 's' : ''}`} placement="top">
						<Button
							style={{
								backgroundColor: numFilters > 0 ? 'rgba(76, 159, 255,.3)' : 'transparent',
								marginBottom: 10,
							}}
							variant="outlined"
							color="primary"
							size="small"
							onClick={filter.isOpen ? closeFilter : openFilter}
							startIcon={<Icons.FilterList />}
						>
							Filter
						</Button>
					</Tooltip>
				}
			/>
			<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
				<Typography style={{ paddingRight: 10 }}>
					{activePageNum * pageSize - pageSize + 1}-{Math.min(numItems, activePageNum * pageSize)} of {numItems}
				</Typography>
				<Pagination
					key={paginationKey}
					color="primary"
					shape="rounded"
					count={numPages}
					page={activePageNum}
					onChange={(event, number) => {
						// setGraphMatches([]);
						setActivePageNum(number);
					}}
				/>
				<div style={{ height: 40 }} />
			</div>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<CandidateFilters
				enableSavedFilters
				savedFilters={savedFilters}
				setSavedFilters={setSavedFilters}
				activeSaveFilterId={activeSaveFilterId}
				setActiveSaveFilterId={setActiveSaveFilterId}
				onClose={closeFilter}
				onReset={() => setFilter({ ...filter, filters: {} })}
				data={data}
				updateFilters={updateFilters}
				filter={filter}
				setFilter={setFilter}
				displayFilters={[
					'match_percentage',
					'fit_ranking',
					'completeness_score_overall',
					'total_skill_score',
					'match_saved',
					'match_shared',
					'match_viewed',
					'professional_experience_years_range',
					'get_the_job_skills',
					'skills',
					'salary_expectation_type_range',
					'location_city',
					'location_state',
					'desired_locations',
					'work_location_preferences',
					'work_visa_type_id',
					'resume',
					'current_title',
					'desired_position',
					'professional_category_id',
					'specialty_id',
					'recent_leadership_role_id',
					'total_leadership_years_range',
					'industries',
					'status',
					'owner',
					// "name",
					// "vendor_candidate_id",
					// "current_employer",
					// "last_profile_update_range",
					// "assessment_completed_range",
					// "score_range"
				]}
			/>

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/candidate/${menu.candidate.id}/profile`} className="color-inherit" target="_blank">
					<MenuItem>
						<ListItemIcon>
							<Icons.Person color="primary" />
						</ListItemIcon>
						Candidate View
					</MenuItem>
				</NavLink>
				<NavLink to={`/candidate/${menu.candidate.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit Candidate
					</MenuItem>
				</NavLink>
				<MenuItem onClick={() => {}}>
					<ListItemIcon>
						<Icons.Assignment color="primary" />
					</ListItemIcon>
					E-mail Profile Link
				</MenuItem>
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						store.dispatch(
							SiteActions.showModal(
								<CandidateDeleteModal candidate={menu.candidate} onDelete={() => table.current.refreshData()} />,
							),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete Candidate
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default JobMatches;
